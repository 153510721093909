.lac-container {
  border: 1px solid #ff8274;
  background: #ff8274;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
  padding: 20px;
  color: #2a2859;
}

.lac-container > p {
  margin: auto;
  padding: 10px 0;
  color: inherit;
  text-align: center;
}

.lac-container > .lac-button {
  display: inline-flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  border: 2px solid #f9c66b;
  padding: 20px;
  margin-bottom: 10px;
  cursor: pointer;
}
