.add-to-homescreen-container {
  width: 100%;
  background-color: white;
  color: black;
  text-align: left;
  -webkit-user-select: none;
  display: flex;
  align-items: top;
}

.add-to-homescreen-not-expanded {
  position: fixed;
  bottom: 0;
  max-height: 46px;
  overflow: hidden;
}
.add-to-homescreen-expanded {
  margin-top: 50px;
}

.add-to-homescreen-content {
  padding: 7px;
}

.add-to-homescreen-content > p > img {
  object-fit: contain;
  max-width: 100%;
  margin: auto;
}

.add-to-homescreen-header {
  display: flex;
  justify-content: space-between;
}
.add-to-homescreen-heading {
  font-size: 1.3em;
  font-weight: normal;
  margin: 0;
  white-space: nowrap;
}
.add-to-homescreen-close-btn {
  padding: 0;
  margin: 0;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background-color: white;
  border: none;
}
.add-to-homescreen-expand-btn {
  padding: 0;
  margin: 0;
  height: 32px;
  background-color: white;
  border: none;
}
.add-to-homescreen-expand-btn:hover {
  text-decoration: underline;
}
