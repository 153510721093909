.app {
  background-color: #282c34;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: white;
}

.app > form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.app > .description {
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 50px 0;
}
.app > .description > p {
  text-align: center;
  padding: 0 10px;
}
