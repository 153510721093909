.legg-til-bilde > .input {
  position: fixed;
  left: -9000px;
}

.legg-til-bilde > svg {
  padding-right: 10px;
}

.legg-til-bilde {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #f9c66b;
  padding: 20px;
  margin-bottom: 10px;
  cursor: pointer;
}

.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  min-width: 60px;
  min-height: 50px;
  text-decoration: none;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  cursor: pointer;
  outline: none;
  user-select: none;
  margin-bottom: 50px;
}
.btn.btn-green {
  color: #c7f6c9;
  background: #034b45;
  border: 2px solid #034b45;
}
.btn:hover,
.btn:active {
  color: white;
  background: #1f42aa;
  border-color: #1f42aa;
}
.btn:disabled {
  color: rgb(116, 116, 116);
  background: #f2f2f2;
  border-color: #f2f2f2;
  cursor: not-allowed;
}

.add-images-preview-item-img {
  object-fit: cover;
  width: 160px;
  height: 160px;
}

.add-images-preview-item-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.add-images-preview-item {
  margin: 5px;
}

.add-images-preview-item-remove {
  position: absolute;
  padding: 0;
  margin: 0;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background-color: white;
  border: none;
}

.spinner {
  position: absolute;
  width: 32px;
  height: 32px;
  color: #034b45;
}
.spinner > .spinner-front {
  opacity: 0.25;
}
.spinner > .spinner-back {
  opacity: 0.75;
}
.animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
