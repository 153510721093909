.toast-container {
  position: absolute;
  bottom: 0;
  width: calc(100vw - 64px);
}
.toast-container > .toast-item {
  width: 100%;
  margin: 16px;
  position: relative;
  padding: 16px;
  border-radius: 3px;
}

.toast-container > .toast-item-success {
  border: 1px solid #034b45;
  background: #c7f6c9;
  color: #034b45;
}

.toast-container > .toast-item-error {
  border: 1px solid #ff8274;
  background: #ff8274;
  color: #2a2859;
}

@media (min-width: 801px) {
  .toast-container > .toast-item {
    max-width: 700px;
    margin: 16px auto;
  }
}
