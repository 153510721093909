@font-face {
  font-family: "OsloSans";
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
  src: url(./assets/OsloSans-Regular.woff2) format("woff2");
}

body {
  margin: 0;
  font-family: "OsloSans", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.125rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5 {
  hyphens: auto;
  font-family: "OsloSans", Arial, sans-serif;
  font-variant-ligatures: contextual;
  font-feature-settings: "calt";
  -moz-font-feature-settings: "calt";
  -webkit-font-feature-settings: "calt";
  -ms-font-feature-settings: "calt";
}

.heading-1 {
  font-size: 1.75rem !important;
  font-weight: 400 !important;
  letter-spacing: -0.9px;
}

@media (min-width: 801px) {
  .heading-1 {
    font-size: 2.5rem !important;
  }
}

@media (min-width: 1024px) {
  .heading-1 {
    font-size: 3.375rem !important;
  }
}
